import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const CategoryContainer = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  listStyle: 'none',
  padding: 0,
  width: 'calc(100% + 3rem)',

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    width: 'calc(100% + 1.5rem)',
  },
}));

export default CategoryContainer;
