import CategoryContainer from 'components/Podcast/primitives/CategoryContainer';
import CategoryOverlay from 'components/Podcast/primitives/CategoryOverlay';
import CategoryTile from 'components/Podcast/primitives/CategoryTile';
import MediaServerImage from 'components/MediaServerImage';
import NavLink from 'components/NavLink';
import Section from 'components/Section';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { gravity, ratio, resize } from 'utils/mediaServerImageMaker/opsString';
import { Network } from 'state/Podcast/types';

type Props = {
  networks: Array<Network>;
};

const NetworksSection = ({ networks }: Props) => {
  const translate = useTranslate();

  if (!networks.length) return null;

  return (
    <Section
      as="h2"
      header={translate('iHeart Podcast Networks')}
      url="/podcast/networks"
    >
      <CategoryContainer>
        {networks.slice(0, 6).map(network => (
          <CategoryTile key={network.title}>
            <NavLink to={network.link.urls.web}>
              <MediaServerImage
                alt={network.title}
                background
                ops={[gravity('center'), ratio(1.77, 1), resize(330, 0)]}
                src={network.img_uri}
              />
              <CategoryOverlay />
            </NavLink>
          </CategoryTile>
        ))}
      </CategoryContainer>
    </Section>
  );
};

export default NetworksSection;
