import CategoryContainer from 'components/Podcast/primitives/CategoryContainer';
import CategoryOverlay from 'components/Podcast/primitives/CategoryOverlay';
import CategoryTile from 'components/Podcast/primitives/CategoryTile';
import GenericHead from 'components/GenericHead';
import H4 from 'primitives/Typography/Headings/H4';
import MediaServerImage, { TILE_RES } from 'components/MediaServerImage';
import NavLink from 'components/NavLink';
import NetworksSection from './NetworksSection';
import PageBody, { ViewName } from 'views/PageBody';
import PodcastDirectoryHead from './PodcastDirectoryHead';
import PodcastSelect from '../PodcastSelect';
import PodcastTiles from 'components/PodcastTiles/PodcastShowMoreTiles';
import Section from 'components/Section';
import Tile from 'components/Tile/Tile';
import Tiles from 'components/Tiles/Tiles';
import { Component } from 'react';
import {
  formatPodcastRecs,
  getPodcastCategoryUrl,
} from 'state/Podcast/helpers';
import { gravity, ratio, resize } from 'utils/mediaServerImageMaker/opsString';

const tilesInRow = 3;

export default class PodcastDirectory extends Component {
  static defaultProps = {
    imgWidth: TILE_RES,
  };

  state = { followedShowsLimit: 6, recShowsLimit: tilesInRow };

  componentDidMount() {
    const {
      categories = [],
      getPodcastCategories,
      getPodcastRecs,
      requestFollowed,
      setHasHero,
    } = this.props;
    requestFollowed();
    setHasHero(false);
    getPodcastRecs();
    if (!categories.length) {
      getPodcastCategories();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !this.props.isAuthenticated &&
      nextProps.isAuthenticated &&
      !nextProps.receivedFollowed
    ) {
      nextProps.requestFollowed();
    }
  }

  onShowMoreClick = name => {
    const { followedShowsLimit, recShowsLimit } = this.state;
    const { followed, recs } = this.props;
    const maxLimit =
      name === 'recs' ? recShowsLimit + 15 : followedShowsLimit + 15;
    const limit =
      name === 'recs' ?
        Math.min(recs.length, maxLimit)
      : Math.min(followed.length, maxLimit);
    return name === 'recs' ?
        this.setState({ recShowsLimit: limit })
      : this.setState({ followedShowsLimit: limit });
  };

  truncatedFavShows() {
    const { followed } = this.props;
    const { followedShowsLimit } = this.state;
    return followed.slice(0, followedShowsLimit);
  }

  render() {
    const {
      categories = [],
      countryCode,
      featuredCategoryPodcasts = [],
      popularCategoryPodcasts = [],
      featuredCategory,
      followed = [],
      popularCategory,
      translate,
      imgWidth,
      podcastRecsFlag,
      recs = [],
    } = this.props;
    const { name } = featuredCategory;
    const isWwUser = countryCode === 'WW';

    const podcastTopics =
      categories.length ?
        categories.map(category => {
          return category.name !== 'Featured' ?
              <CategoryTile
                data-test="podcast-directory-topic-tile"
                key={category.id}
              >
                <NavLink to={getPodcastCategoryUrl(category.name, category.id)}>
                  <MediaServerImage
                    alt={category.name}
                    background
                    ops={[gravity('center'), ratio(1.77, 1), resize(330, 0)]}
                    src={category.image}
                  />
                  <CategoryOverlay data-test="podcast-directory-podcast-overlay" />
                </NavLink>
              </CategoryTile>
            : null;
        })
      : null;

    const dummyPodcastRecs = [];
    for (let i = 0; i < 3; i += 1)
      dummyPodcastRecs.push(
        <Tile
          isDummyTile
          key={`dummyTile${i}`}
          singleRow
          tilePosition={i}
          tilesInRow={tilesInRow}
        />,
      );

    const podcastRecs = (
      <Section
        header={
          <H4 as="h2" style={{ marginBottom: '2rem' }}>
            {translate('Recommended For You')}
          </H4>
        }
        isHidden={recs.length < 5}
      >
        {recs.length ?
          <PodcastTiles
            imgWidth={imgWidth}
            podcasts={formatPodcastRecs(recs, followed)}
            tilesInRow={tilesInRow}
          />
        : <Tiles tilesInRow={tilesInRow}>{dummyPodcastRecs}</Tiles>}
      </Section>
    );

    return (
      <>
        <GenericHead
          deeplink="goto/podcast"
          description={translate(
            'Listen to the latest episodes from top podcasts. Get news, sports & lifestyle info from your favorite personalities for free!',
          )}
          legacyDeeplink="goto/podcast"
          metaTitle={translate(
            'Listen to the Best Podcasts Online for Free on iHeart',
          )}
          ogType="website"
          pagePath="/podcast/"
          title={translate('Listen to the Best Podcasts & Shows Online, Free')}
        />
        <PodcastDirectoryHead
          keywords={translate(
            'Talk, Show, Host, Personality, On Demand, Topic, Category, Health, News, Politics, Comedy, Station, Internet, Online, iHeartRadio, iHeart',
          )}
        />

        <PageBody
          dataTest={ViewName.PodcastDirectory}
          isWwUser={isWwUser}
          mainStyles={isWwUser ? { display: 'block', margin: '0 auto' } : {}}
          more={isWwUser ? <div /> /* to force section type='left' */ : null}
          noAds={isWwUser}
          title={translate('Stream Top Podcasts')}
        >
          <PodcastSelect
            categories={categories}
            title={translate('All Topics')}
          />
          {podcastRecsFlag ? podcastRecs : null}
          <Section
            dataTest="popular-podcast-section"
            header={
              <H4
                as="h2"
                data-test="popular-podcasts-section-heading"
                style={{ marginBottom: '2rem' }}
              >
                {translate('{name} Podcasts', {
                  name: popularCategory?.name ?? '',
                })}
              </H4>
            }
            isHidden={!popularCategoryPodcasts.length}
          >
            <PodcastTiles
              imgWidth={imgWidth}
              podcasts={popularCategoryPodcasts}
              tilesInRow={tilesInRow}
            />
          </Section>
          <Section
            dataTest="featured-podcast-section"
            header={
              <H4
                as="h2"
                data-test="featured-podcasts-section-heading"
                style={{ marginBottom: '2rem' }}
              >
                {translate('{name} Podcasts', { name })}
              </H4>
            }
            isHidden={!featuredCategoryPodcasts.length}
          >
            <PodcastTiles
              imgWidth={imgWidth}
              podcasts={featuredCategoryPodcasts}
              tilesInRow={tilesInRow}
            />
          </Section>
          <NetworksSection />
          <Section
            dataTest="podcast-genre-section"
            header={
              <H4
                as="h2"
                data-test="podcast-genre-section-heading"
                style={{ marginBottom: '2rem' }}
              >
                {translate('Topics')}
              </H4>
            }
            isHidden={!categories.length}
          >
            <CategoryContainer data-test="podcast-directory-topics">
              {podcastTopics}
            </CategoryContainer>
          </Section>
        </PageBody>
      </>
    );
  }
}
