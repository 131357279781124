import styled from '@emotion/styled';

const CategoryOverlay = styled('span')(({ theme }) => ({
  background: theme.colors.black.dark,
  borderRadius: '0.8rem',
  height: '100%',
  left: 0,
  opacity: 0,
  position: 'absolute',
  top: 0,
  transition: 'opacity 0.3s',
  width: '100%',

  '&:hover': {
    opacity: 0.5,
  },
}));

export default CategoryOverlay;
