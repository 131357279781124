import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

const CategoryTile = styled('li')(({ theme }) => ({
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.15)',
  marginBottom: '3rem',
  marginRight: '3rem',
  position: 'relative',
  width: 'calc(33.33% - 3rem)',
  borderRadius: '0.8rem',
  overflow: 'hidden',

  '.background': {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },

  [mediaQueryBuilder(theme.mediaQueries.max.width['1160'])]: {
    marginBottom: '1.5rem',
    marginRight: '1.5rem',
    width: 'calc(50% - 1.5rem)',
  },
}));

export default CategoryTile;
